import fs from "fs";
import { getWorksheet } from "./utils";

enum Locales {
  en = "EN",
  fr = "FR",
  es = "ES",
  "ca-ES" = "CA(ES)",
  it = "IT",
  pt = "PT",
  "fr-nc" = "FR(NC)",
  "es-ic" = "ES(IC)",
  "es-ad" = "ES(ANDORRA)",
  de = "DE"
}

enum RowInfos {
  key = "KEY",
  type = "1"
}

type RowValues = { [K in Locales]: string };
type RowInfosValues = { [K in RowInfos]: string };

/**
 * Returns translation value according to a given language
 * Fallback to:
 *   - es for ca-ES, es-ic and es-ad
 *   - fr for fr-nc
 *
 * @param {Object} row - contains values for all languages
 * @param {string} key - language key of the value we want to retrieve
 *
 * @returns {string}
 */
const getValue = (row: RowValues, key: Locales): string => {
  if (row[key] !== "") {
    return row[key];
  }

  if (key === "FR(NC)" && row["FR"]) {
    return row["FR"];
  }

  if ((key === "ES(IC)" || key === "ES(ANDORRA)") && row["ES"]) {
    return row["ES"];
  }

  return row["EN"];
};

/**
 * Escapes characters such as double quote and carriage returns
 *
 * @param {string} value
 *
 * @returns {string}
 */
const makeSafeValue = (value: string): string =>
  value
    .replace(/"/g, '\\"')
    .replace(/\r/g, "\\n")
    .replace(/\n/g, "\\n")
    .replace(/\r\n/g, "\\n");

/**
 * Write translation value in specified path for all locales
 *
 * @param {string} rootPath - path of the translation files
 * @param {string} cellType - attribute to look at to check the type of the row
 * @param {Object} row
 *
 * @returns {void}
 */
const writeValue = (rootPath: string, cellType: RowInfos) => (row: RowValues & RowInfosValues) => {
  Object.entries(Locales).map(([langKey, keyInSheet]) => {
    const path = `${rootPath}/${langKey}.lproj/Localizable.strings`;
    let value;

    switch (row[cellType]) {
      case "APP":
        value = `\n // MARK: - App: ${row["KEY"]} - \n // --------------------------------- \n`;
        break;
      case "MODULE":
        value = `\n // MARK: - Module: ${row["KEY"]} - \n // --------------------------------- \n`;
        break;
      case "CATEGORY":
        value = `\n // MARK: - ${row["KEY"]} \n`;
        break;
      case "":
        if (row["KEY"]) {
          value = getValue(row, keyInSheet);
          const translationValue = value ? makeSafeValue(value) : value;
          value = `"${row["KEY"]}" = "${translationValue}";\n`;
        }
        break;
      default:
        break;
    }

    value && fs.appendFileSync(path, value);
  });
};

export const generatePosTranslations = async (
  spreadsheetId: string,
  worksheetId: string,
  pathToTranslationFiles: string = ".",
  pathToConfig: string = './google-credentials.json',
) => {
  if (spreadsheetId && worksheetId) {
    const sheet = await getWorksheet(spreadsheetId, worksheetId, pathToConfig);

    await sheet.loadHeaderRow();
    const [cellType] = sheet.headerValues;

    const [, ...rows] = await sheet.getRows();

    Object.entries(Locales).map(([langKey]) => {
      if (!fs.existsSync(`${pathToTranslationFiles}/${langKey}.lproj`)) {
        fs.mkdirSync(`${pathToTranslationFiles}/${langKey}.lproj`);
      }
      let generatedTime = new Date();
      const value = `\n/*  AUTO-GENERATED: ${generatedTime.toISOString()} */\n/*  GOOGLE SHEETS LINK: https://docs.google.com/spreadsheets/d/1xyONg14hA_vkCt6tENScp0I_MPIvscsdgI9ZLO1YkBA/edit#gid=0  */\n\n`;
      fs.writeFileSync(`${pathToTranslationFiles}/${langKey}.lproj/Localizable.strings`, value);
    });

    if (!fs.existsSync(`${pathToTranslationFiles}/Base.lproj`)) {
      fs.mkdirSync(`${pathToTranslationFiles}/Base.lproj`);
    }

    rows.map(writeValue(pathToTranslationFiles, cellType));

    fs.copyFileSync(
      `${pathToTranslationFiles}/en.lproj/Localizable.strings`,
      `${pathToTranslationFiles}/Base.lproj/Localizable.strings`
    );
  } else {
    console.log("Please provide spreadsheetId and worksheetId values");
  }
};
