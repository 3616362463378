import dotenv from "dotenv";
import mod from "google-spreadsheet";
import fs from "fs";
import _ from "lodash";

dotenv.config();
const { GoogleSpreadsheet } = mod;

export const getWorksheet = async (
  spreadsheetId: string,
  worksheetId: string,
  pathToConfig: string
) => {
  try {
    const credentialsFile = fs.readFileSync(pathToConfig, "utf8");
    const credentials = JSON.parse(credentialsFile);
    const doc = new GoogleSpreadsheet(spreadsheetId);
    await doc.useServiceAccountAuth(credentials);
    await doc.loadInfo();
    return doc.sheetsById[worksheetId];
  } catch (e) {
    console.error(e.message);
  }
};

export interface LooseObject {
  [key: string]: any;
}

/**
 * Put a value in an object with already existing values or not
 * E.g. for an object
 *  {
 *    keyA: {
 *      keyB: valueB
 *    },
 *    keyC: valueC
 *  },
 * and a path ['keyA', 'keyD', 'keyE', 'valueE'], resulting object will be:
 *  {
 *    keyA: {
 *      keyB: valueB,
 *      keyD: {
 *        keyE: valueE
 *      }
 *    },
 *    keyC: valueC
 *  }
 *
 * @param {Array} path - path of the value in the object. Must be strings which will be the nested keys
 *                       Last value is the value of the property we want to insert.
 * @param {Object} o - Object where value must be inserted
 */
export const nest = (
  [current, ...keys]: Array<string>,
  o: LooseObject = {}
): string | LooseObject =>
  keys.length === 0 ? current : ((o[current] = nest(keys, o[current])), o);

export const sortObject = (object: LooseObject): LooseObject => {
  let sortedObj: LooseObject = {};
  const keys = Object.keys(object);

  keys.sort((key1, key2) => {
    (key1 = key1.toLowerCase()), (key2 = key2.toLowerCase());
    if (key1 < key2) return -1;
    if (key1 > key2) return 1;
    return 0;
  });

  for (let index in keys) {
    const key = keys[index];
    if (typeof object[key] === "object" && !(object[key] instanceof Array)) {
      sortedObj[key] = sortObject(object[key]);
    } else {
      sortedObj[key] = object[key];
    }
  }

  return sortedObj;
};

/**
 * Remove any nested empty object in the specified object.
 *
 * @param {object} obj - any object
 *
 * @returns {object}
 */
export const removeEmptyObjects = (obj: any): any => {
  return _(obj)
    .pickBy(_.isObject)
    .mapValues(removeEmptyObjects)
    .omitBy(_.isEmpty)
    .assign(_.omitBy(obj, _.isObject))
    .value();
};

/**
 * Get the path of an object property.
 *
 * @param obj - any object
 * @param key - the key of the property you want path for
 * @param value - the value of the property you want path for
 *
 * @returns {string}
 */
export const getPath = (
  obj: any,
  key: string,
  value: string,
  currentPath?: string,
  lastPath?: string
): string => {
  var newPath = currentPath || "root";

  for (var i in obj) {
    if (i == key && obj[i] == value) {
      lastPath = newPath;
    } else if (typeof obj[i] == "object") {
      return getPath(obj[i], key, value, newPath + "." + i);
    }
  }

  return lastPath + "." + key;
};

/**
 * Flattens objects, returning a one-level object.
 *
 * @param obj - any object
 *
 * @returns {object}
 */
export const flattenObject = (obj: any) => {
  var toReturn: any = {};

  for (var i in obj) {
    if (!obj.hasOwnProperty(i)) continue;

    if (typeof obj[i] == "object") {
      var flatObject = flattenObject(obj[i]);
      for (var x in flatObject) {
        if (!flatObject.hasOwnProperty(x)) continue;

        toReturn[i + "." + x] = flatObject[x];
      }
    } else {
      toReturn[i] = obj[i];
    }
  }
  return toReturn;
};
