/** Returns the fallback language code
 * examples with availableLanguages = ['fr', 'en', 'en-GB', 'es']:
 * - fr => ["en"]
 * - fr-CA => ["fr", "en"]
 * - es-MX => ["es", "en"]
 * - ja => ["en"]
 * - vtt => ["en"]
 * - en-GB => ['en']
 */
export const getFallbackLanguagesCodes = ({
  availableLanguages,
  defaultFallbackLanguage = "en",
  languageCode,
}: {
  availableLanguages: string[];
  defaultFallbackLanguage?: string;
  languageCode: string;
}): string[] => {
  // list of fallback languages, ordered by priority
  const fallbackLanguages = [];

  if (languageCode?.length > 2) {
    // get the 2 lowercase letters before the "-", returning the language without the region code
    const match = languageCode.match("^([a-z]{2})-.*$");
    if (match?.length === 2) {
      const languageWithoutRegionCode = match[1];

      // if the languageWithoutRegionCode is in availableLanguages and it's not {defaultFallbackLanguage}, add it as a first priority in fallbackLanguages
      if (
        availableLanguages.includes(languageWithoutRegionCode) &&
        languageWithoutRegionCode !== defaultFallbackLanguage
      ) {
        fallbackLanguages.push(languageWithoutRegionCode);
      }
    }
  }

  // finally push {defaultFallbackLanguage} as our last fallbackLanguage
  fallbackLanguages.push(defaultFallbackLanguage);

  return fallbackLanguages;
};
