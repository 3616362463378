import fs from "fs";
import { flattenObject, getWorksheet, removeEmptyObjects, sortObject } from "./utils";
import { setWith, toPairs, uniq, unset } from "lodash";
import chalk from "chalk";

type NewRows = { [key: string]: { [lang: string]: string } };

enum Locales {
  ca = "CA(ES)",
  de = "DE",
  en = "EN",
  "en-GB" = "EN(UK)",
  es = "ES",
  fr = "FR",
  it = "IT",
  pt = "PT",
}

enum RowInfos {
  Key = "KEY",
  Type = "CATEGORY",
}

/**
 * Escapes carriage returns
 *
 * @param {string} value
 *
 * @returns {string}
 */
const makeSafeValue = (value: string): string =>
  value
    .replace(/\r/g, "")
    .replace(/\n/g, "")
    .replace(/\r\n/g, "");

const gsheetUndefinedValues: string[] = [];

/**
 * Should return the correct value to be put in translation file
 *
 * @param row - row to work on
 * @param lang - language to work with
 * @param currentTranslation - current translation available
 */
const getCorrectValue = (row: any, lang: string, currentTranslation: any): string | undefined => {
  if (row[lang] === "" || row[lang] === undefined) {
    if (row[Locales.en] === "") {
      gsheetUndefinedValues.push(row[RowInfos.Key]);
    }

    return undefined;
  }

  unset(currentTranslation, row[RowInfos.Key]);

  return makeSafeValue(row[lang]);
};

/**
 * Add new rows to the spreadsheet
 *
 * @param rows - rows you want to add, should be in { 'KEY': { 'EN': '', 'FR': '', ... } } format
 */
const addNewRows = (sheet: any, rows: NewRows) => {
  const rowsToAdd: any[] = Object.keys(rows).map((key) => ({
    [RowInfos.Key]: key,
    ...rows[key],
  }));

  if (rowsToAdd.length === 0) return;

  sheet.addRows(rowsToAdd);

  let logMessage = `\n[${chalk.blue(
    "NEW KEYS"
  )}] The following keys have been added to the spreadsheet:\n`;
  rowsToAdd.forEach((row) => {
    logMessage += `\t\u2022 ${row[RowInfos.Key]} (${Object.keys(rows[row[RowInfos.Key]])})\n`;
    if (row[Locales.en] === undefined) {
      gsheetUndefinedValues.push(row[RowInfos.Key]);
    }
  });
  console.info(logMessage);
};

/**
 * Logs spreadsheet undefined values
 */
const logUndefinedValues = () => {
  if (gsheetUndefinedValues.length === 0) return;

  const uniqUndefinedValues = uniq(gsheetUndefinedValues);
  let warningMessage = `\n[${chalk.yellow("WARNING")}] The following keys have no english value:\n`;
  uniqUndefinedValues.forEach((value) => {
    warningMessage += `\t\u2022 ${value}\n`;
  });

  console.warn(warningMessage);
};

/**
 * Generates translations for Sonar
 *
 * @param spreadsheetId - id of the spreadsheet you're working on
 * @param worksheetId - id of the worksheet you're working on in the spreadsheet
 * @param pathToTranslationFiles - path in which you want translation to be generated
 * @param pathToConfig - path to google credentials file
 */
export const generateSonarTranslations = async (
  spreadsheetId: string,
  worksheetId: string,
  pathToTranslationFiles: string = ".",
  pathToConfig: string = "./google-credentials.json"
) => {
  if (spreadsheetId && worksheetId) {
    const sheet = await getWorksheet(spreadsheetId, worksheetId, pathToConfig);

    await sheet.loadHeaderRow();
    const [, ...rows] = await sheet.getRows();

    const newRows: any = {};

    Object.entries(Locales).forEach(([langKey, lang]) => {
      let currentTranslation: any = {};
      const translation = {};

      if (fs.existsSync(`${pathToTranslationFiles}/${langKey}/translations.json`)) {
        const currentTranslationFile = fs.readFileSync(
          `${pathToTranslationFiles}/${langKey}/translations.json`,
          "utf8"
        );
        currentTranslation = JSON.parse(currentTranslationFile);
      }

      for (const row of rows) {
        const value = getCorrectValue(row, lang, currentTranslation);
        setWith(translation, row[RowInfos.Key], value, Object);
      }

      const flatTranslation: any = flattenObject(removeEmptyObjects(currentTranslation));
      Object.keys(flatTranslation).forEach((key) => {
        newRows[key] = { ...newRows[key], [lang]: flatTranslation[key] };
      });

      fs.writeFileSync(
        `${pathToTranslationFiles}/${langKey}/translations.json`,
        JSON.stringify(sortObject({ ...currentTranslation, ...translation }), null, 2)
      );
    });

    addNewRows(sheet, newRows);
    logUndefinedValues();
  }
};
